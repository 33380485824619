<template>
  <v-container
    fluid
    style=" background:rgba(255,255,255,0.9);min-width:100%; min-height:95vh; padding:10px 20px;position:relative;"
  >
    <h6
      @click="back"
      style="border:1px solid rgba(0,0,0,0.3); border-radius:5px; position:absolute; width:70px; cursor:pointer;"
    >
      <v-icon style="position:relative; top:-1px;">mdi-chevron-left</v-icon>back
    </h6>
    <div style="margin-bottom:65px;">
      <h4
        style="
  margin: auto;
  width: 50%;
  font-weight: bold;
  text-align: center;
  font-size: 20px;"
      >
        FORMULIR TRANSAKSI
      </h4>

      <v-row no-gutters style="margin-top:20px;">
        <v-col cols="8" style="padding:5px;">
          <v-row>
            <v-col cols="12">
              <v-form
                :disabled="loading"
                lazy-validation
                ref="entryForm"
                @submit.prevent="submit"
                style="position: relative;"
              >
                <v-card elevation="1" style="padding:5px;">
                  <div style="margin:5px 0">
                    <v-row no-gutters>
                      <v-col cols="3">
                        <div style="padding: 0 10px">
                          <v-col cols="12" style="padding: 0">
                            <p
                              style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                            >
                              Tipe Transaksi
                            </p>
                          </v-col>
                          <v-col cols="12" style="padding: 0;">
                            <v-select
                              v-model="form.order_type"
                              :items="[
                                { id: 1, name: 'Internal' },
                                { id: 2, name: 'Eksternal' }
                              ]"
                              item-text="name"
                              item-value="id"
                              return-id
                              dense
                              outlined
                              disabled
                            ></v-select>
                          </v-col>
                        </div>
                      </v-col>
                      <v-col cols="5">
                        <div style="padding: 0 10px">
                          <v-col cols="12" style="padding: 0">
                            <p
                              style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                            >
                              No. Transaksi
                            </p>
                          </v-col>
                          <v-col cols="12" style="padding: 0;">
                            <v-text-field
                              dense
                              outlined
                              v-model="form.odoo_order_no"
                              :rules="rules.order_no"
                            />
                          </v-col>
                        </div>
                      </v-col>
                      <v-col cols="4">
                        <div style="padding: 0 10px">
                          <v-col cols="12" style="padding: 0">
                            <p
                              style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                            >
                              Tgl. Transaksi
                            </p>
                          </v-col>
                          <v-col cols="12" style="padding: 0;">
                            <v-text-field
                              dense
                              outlined
                              type="date"
                              name="date"
                              step="1"
                              v-model="form.order_date"
                              :rules="rules.order_date"
                            />
                          </v-col>
                        </div>
                      </v-col>
                      <v-col cols="6">
                        <div style="padding: 0 10px">
                          <v-col cols="12" style="padding: 0">
                            <p
                              style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                            >
                              Perusahaan
                            </p>
                          </v-col>
                          <v-col cols="12" style="padding: 0;">
                            <v-autocomplete
                              v-model="form.company"
                              :items="dropdown.company"
                              :rules="rules.company"
                              item-text="name"
                              item-value="id"
                              return-object
                              outlined
                              dense
                              style="margin: 0"
                              clearable
                            ></v-autocomplete>
                          </v-col>
                        </div>
                      </v-col>
                      <v-col cols="6">
                        <div style="padding: 0 10px">
                          <v-col cols="12" style="padding: 0">
                            <p
                              style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                            >
                              Customer
                            </p>
                          </v-col>
                          <v-col cols="12" style="padding: 0;">
                            <v-autocomplete
                              v-model="form.customer"
                              :items="dropdown.customer"
                              :rules="rules.customer"
                              item-text="name"
                              item-value="id"
                              return-object
                              outlined
                              dense
                              style="margin: 0"
                              clearable
                            ></v-autocomplete>
                          </v-col>
                        </div>
                      </v-col>
                      <v-col cols="6">
                        <div style="padding: 0 10px">
                          <v-col cols="12" style="padding: 0">
                            <p
                              style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                            >
                              Lokasi Muat Barang
                            </p>
                          </v-col>
                          <v-col cols="12" style="padding: 0;">
                            <v-autocomplete
                              v-model="form.loading_from_location"
                              :items="dropdown.loadingLocation"
                              :search-input.sync="
                                dropdown.loadingLocationSearch
                              "
                              :rules="rules.loading"
                              label="Cari"
                              item-text="name"
                              item-value="id"
                              return-object
                              outlined
                              dense
                              clearable
                            ></v-autocomplete>
                          </v-col>
                        </div>
                      </v-col>
                      <v-col cols="6">
                        <div style="padding: 0 10px">
                          <v-col cols="12" style="padding: 0">
                            <p
                              style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                            >
                              Lokasi Bongkar Barang
                            </p>
                          </v-col>
                          <v-col cols="12" style="padding: 0;">
                            <v-autocomplete
                              v-model="form.unloading_location"
                              :items="dropdown.unloadingLocation"
                              :search-input.sync="
                                dropdown.unloadingLocationSearch
                              "
                              :rules="rules.unloading"
                              label="Cari"
                              item-text="name"
                              item-value="id"
                              return-object
                              outlined
                              dense
                              clearable
                            ></v-autocomplete>
                          </v-col>
                        </div>
                      </v-col>
                      <v-col cols="4">
                        <div style="padding: 0 10px">
                          <v-col cols="12" style="padding: 0">
                            <p
                              style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                            >
                              Driver
                            </p>
                          </v-col>
                          <v-col cols="12" style="padding: 0;">
                            <v-autocomplete
                              v-model="form.driver"
                              :items="dropdown.driver"
                              :search-input.sync="dropdown.driverSearch"
                              :rules="rules.driver"
                              label="Cari"
                              item-text="name"
                              item-value="id"
                              return-object
                              outlined
                              dense
                              clearable
                            >
                              <template v-slot:item="{ item }">
                                <div style="background:rgba(255,255,255,0.1);">
                                  {{ item.name + ' ' + '(' + item.nik + ')' }}
                                </div>
                              </template>
                            </v-autocomplete>
                          </v-col>
                        </div>
                      </v-col>
                      <v-col cols="4">
                        <div style="padding: 0 10px">
                          <v-col cols="12" style="padding: 0">
                            <p
                              style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                            >
                              No. Kendaraan
                            </p>
                          </v-col>
                          <v-col cols="12" style="padding: 0;">
                            <v-autocomplete
                              v-model="form.vehicle"
                              :items="dropdown.vehicle"
                              :search-input.sync="dropdown.vehicleSearch"
                              :rules="rules.vehicle"
                              label="Cari"
                              item-text="license_plate"
                              item-value="id"
                              return-object
                              outlined
                              dense
                              clearable
                            ></v-autocomplete>
                          </v-col>
                        </div>
                      </v-col>
                      <v-col cols="4">
                        <div style="padding: 0 10px">
                          <v-col cols="12" style="padding: 0">
                            <p
                              style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                            >
                              Tipe Kendaraan
                            </p>
                          </v-col>
                          <v-col cols="12" style="padding: 0;">
                            <v-text-field
                              dense
                              outlined
                              disabled
                              :value="
                                form.vehicle !== null
                                  ? form.vehicle.vehicle_type.name
                                  : ''
                              "
                            />
                          </v-col>
                        </div>
                      </v-col>

                      <v-col cols="12">
                        <div style="padding: 0 10px">
                          <v-col cols="12" style="padding: 0">
                            <p
                              style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                            >
                              Catatan
                            </p>
                          </v-col>
                          <v-col cols="12" style="padding: 0;">
                            <v-textarea
                              dense
                              auto-grow
                              rows="3"
                              outlined
                              v-model="form.note"
                            />
                          </v-col>
                        </div>
                      </v-col>
                      <v-col cols="4">
                        <div style="padding: 0 10px">
                          <v-col cols="12" style="padding: 0">
                            <p
                              style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                            >
                              Produk
                            </p>
                          </v-col>
                          <v-col cols="12" style="padding: 0;">
                            <v-autocomplete
                              v-model="form.product"
                              :items="dropdown.product"
                              item-text="name"
                              item-value="id"
                              return-object
                              outlined
                              dense
                              style="margin: 0"
                              clearable
                            >
                              <template v-slot:item="{ item }">
                                <div style="background:rgba(255,255,255,0.1);">
                                  {{ item.name + ' ' + '(' + item.code + ')' }}
                                </div>
                              </template>
                            </v-autocomplete>
                          </v-col>
                        </div>
                      </v-col>
                      <v-col cols="4">
                        <div style="padding: 0 10px">
                          <v-col cols="12" style="padding: 0">
                            <p
                              style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                            >
                              Satuan
                            </p>
                          </v-col>
                          <v-col cols="12" style="padding: 0;">
                            <v-autocomplete
                              v-model="form.unit"
                              :items="dropdown.unit"
                              item-text="name"
                              item-value="id"
                              return-object
                              outlined
                              dense
                              style="margin: 0"
                              clearable
                            >
                            </v-autocomplete>
                          </v-col>
                        </div>
                      </v-col>
                      <v-col cols="2">
                        <div style="padding: 0 10px">
                          <v-col cols="12" style="padding: 0">
                            <p
                              style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                            >
                              Qty
                            </p>
                          </v-col>
                          <v-col cols="12" style="padding: 0;">
                            <v-text-field dense outlined v-model="form.qty" />
                          </v-col>
                        </div>
                      </v-col>
                      <v-col
                        cols="2"
                        class="d-flex align-center"
                        style="height:70px;"
                      >
                        <v-btn
                          @click="addItem"
                          x-small
                          fab
                          elevation="1"
                          color="indigo"
                          class="white--text font-weight-bold"
                          style="font-size:14px;margin-top:5px;"
                        >
                          +
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
                <div
                  class="d-flex justify-content-between"
                  style="padding: 10px 0;"
                >
                  <div>
                    <v-btn
                      @click="clearForm"
                      color="error"
                      type="reset"
                      :loading="loading"
                    >
                      Batal
                    </v-btn>
                  </div>
                  <v-btn color="primary" type="submit" :loading="loading">
                    SUBMIT DATA
                  </v-btn>
                </div>
              </v-form>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4" style="padding:5px;">
          <v-row>
            <v-col cols="12">
              <v-card elevation="1" style="padding:5px;">
                <div
                  style="margin-left:5px; font-size:14px; font-weight:bold;margin-bottom:10px;border-bottom:1px solid black;"
                >
                  DETAIL BARANG
                </div>
                <v-data-table
                  mobile-breakpoint="0"
                  :headers="itemTableHeaders"
                  :items="form.order_details"
                  :items-per-page="10"
                  class="elevation-1 mx-auto"
                  hide-default-footer
                >
                  <template v-slot:[`item.actions`]="{ item, index }">
                    <div
                      style="display:flex; flex-direction:row;justify-content:center;"
                    >
                      <v-btn
                        color="error"
                        class="ma-1 white--text"
                        small
                        icon
                        @click="itemDelete(item, index)"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" small class="">
                              mdi-delete
                            </v-icon>
                          </template>
                          <span>Delete</span>
                        </v-tooltip>
                      </v-btn>
                    </div>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
            <!-- <v-col cols="12">
              <v-card elevation="1" style="padding:5px;">
                <div
                  style="font-size:14px; font-weight:bold; margin-bottom:10px; border-bottom:1px solid black;"
                >
                  DETAIL TAGIHAN
                </div>
                <div style="padding:0 5px 5px 5px; margin-bottom:10px;">
                  <div class="d-flex justify-content-between align-center">
                    <p
                      style="width:50%; margin:auto 0;display:flex; justify-content:space-between; font-size:14px;"
                    >
                      <span>
                        {{ 'tipe kendaraan' + ' (' + 'zona' + ') ' }}</span
                      >
                      <span style="font-weight:bolder:margin-left:5px;">:</span>
                    </p>
                    <p
                      class="d-flex justify-end"
                      style="width: 40%; font-size:14px; border-bottom:1px dotted black; padding:0 10px;margin:auto 0;"
                    >
                      <span>Rp. {{ priceFormat(0) }}</span>
                    </p>
                  </div>
                </div>
                <div style="padding:0 5px 5px 5px; margin-bottom:10px;">
                  <div class="d-flex justify-content-between align-center">
                    <p
                      style="width:50%; margin:auto 0;display:flex; justify-content:space-between; font-size:14px; font-weight:bold;"
                    >
                      <span>
                        Harga standar
                      </span>
                    </p>
                  </div>
                  <div class="d-flex justify-content-between align-center">
                    <p
                      style="width:50%; margin:auto 0;display:flex; justify-content:space-between; font-size:14px;"
                    >
                      <span> + {{ 0 }} Km</span>
                      <span style="font-weight:bolder:margin-left:5px;">:</span>
                    </p>
                    <p
                      class="d-flex justify-end"
                      style="width: 40%; font-size:14px; border-bottom:1px dotted black; padding:0 10px;margin:auto 0;"
                    >
                      <span>Rp. {{ priceFormat(0) }}</span>
                    </p>
                  </div>
                  <div class="d-flex justify-content-between align-center">
                    <p
                      style="width:50%; margin:auto 0;display:flex; justify-content:space-between; font-size:14px;"
                    >
                      <span> Total harga</span>
                      <span style="font-weight:bolder:margin-left:5px;">:</span>
                    </p>
                    <p
                      class="d-flex justify-end"
                      style="width: 40%; font-size:14px; border-bottom:1px dotted black; padding:0 10px;margin:auto 0;"
                    >
                      <span>Rp. {{ priceFormat(0) }}</span>
                    </p>
                  </div>
                </div>
              </v-card>
            </v-col> -->
            <!-- <v-col cols="12">
              <v-card elevation="1" style="padding:5px;">
                <div
                  style="margin-left:5px; font-size:14px; font-weight:bold;margin-bottom:10px;border-bottom:1px solid black;"
                >
                  DETAIL UANG JALAN
                </div>
                <v-row no-gutters>
                  <v-col cols="6">
                    <div style="padding: 0 10px">
                      <v-col cols="12" style="padding: 0">
                        <p
                          style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                        >
                          Jarak Tempuh
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0;">
                        <v-text-field
                          style="font-size:14px;"
                          dense
                          readonly
                          :value="'...' + ' (Km)'"
                        />
                      </v-col>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <div style="padding: 0 10px">
                      <v-col cols="12" style="padding: 0">
                        <p
                          style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                        >
                          BBM
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0;">
                        <v-text-field
                          style="font-size:14px;"
                          dense
                          readonly
                          :value="'Rp. ' + priceFormat(Math.round(0))"
                        />
                      </v-col>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <div style="padding: 0 10px">
                      <v-col cols="12" style="padding: 0">
                        <p
                          style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                        >
                          Toll
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0;">
                        <v-text-field
                          style="font-size:14px;"
                          dense
                          readonly
                          :value="'Rp. ' + priceFormat(Math.round(0))"
                        />
                      </v-col>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <div style="padding: 0 10px">
                      <v-col cols="12" style="padding: 0">
                        <p
                          style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                        >
                          Muat Barang
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0;">
                        <v-text-field
                          style="font-size:14px;"
                          dense
                          readonly
                          :value="'Rp. ' + priceFormat(Math.round(0))"
                        />
                      </v-col>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <div style="padding: 0 10px">
                      <v-col cols="12" style="padding: 0">
                        <p
                          style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                        >
                          Bongkar Barang
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0;">
                        <v-text-field
                          style="font-size:14px;"
                          dense
                          readonly
                          :value="'Rp. ' + priceFormat(Math.round(0))"
                        />
                      </v-col>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <div style="padding: 0 10px">
                      <v-col cols="12" style="padding: 0">
                        <p
                          style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                        >
                          MEL
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0;">
                        <v-text-field
                          style="font-size:14px;"
                          dense
                          readonly
                          :value="'Rp. ' + priceFormat(Math.round(0))"
                        />
                      </v-col>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <div style="padding: 0 10px">
                      <v-col cols="12" style="padding: 0">
                        <p
                          style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                        >
                          Lain-Lain
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0;">
                        <v-text-field
                          style="font-size:14px;"
                          dense
                          readonly
                          :value="'Rp. ' + priceFormat(Math.round(0))"
                        />
                      </v-col>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <div style="padding: 0 10px">
                      <v-col cols="12" style="padding: 0">
                        <p
                          style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                        >
                          Rate Supir
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0;">
                        <v-text-field
                          style="font-size:14px;"
                          dense
                          readonly
                          :value="'Rp. ' + priceFormat(Math.round(0))"
                        />
                      </v-col>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <div style="padding: 0 10px">
                      <v-col cols="12" style="padding: 0">
                        <p
                          style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                        >
                          Rate Kenek
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0;">
                        <v-text-field
                          style="font-size:14px;"
                          dense
                          readonly
                          :value="'Rp. ' + priceFormat(Math.round(0))"
                        />
                      </v-col>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <div style="padding: 0 10px">
                      <v-col cols="12" style="padding: 0">
                        <p
                          style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                        >
                          Total
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0;">
                        <v-text-field
                          style="font-size:14px;"
                          dense
                          readonly
                          :value="'Rp. ' + priceFormat(Math.round(0))"
                        />
                      </v-col>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <v-row no-gutters style="padding: 0 10px">
                      <v-col
                        cols="6"
                        style="padding: 0; margin:auto;"
                        class="d-flex"
                      >
                        <p
                          style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                      "
                        >
                          TOTAL BIAYA
                        </p>
                        <p
                          style="
                        margin: 0 0 0 15px;
                        font-size: 12px;
                      "
                        >
                          :
                        </p>
                      </v-col>
                      <v-col cols="6" style="padding: 0;">
                        <v-text-field
                          style="font-size:16px; font-weight:bold;"
                          dense
                          readonly
                          :value="'Rp. ' + priceFormat(Math.round(0))"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </v-col> -->
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div
      v-if="loading"
      class="d-flex justify-center align-center"
      style="position:fixed; top:0; left:0; right:0; bottom:0;z-index:6; background-color:rgba(255,255,255,0.8);"
    >
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>

    <div
      v-if="loading"
      class="d-flex justify-center align-center"
      style="position:fixed;top:0; bottom:0; z-index:3; left:0;width:100%; background:rgba(255,255,255,0.5);"
    >
      <v-progress-circular
        :size="100"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
  </v-container>
</template>
<script>
import axios from 'axios'
// import { mapGetters, mapActions } from 'vuex'
import buildType from '../../../services/buildType'
export default {
  name: 'ordertransactionform',
  components: {},
  data() {
    return {
      expedition: buildType.apiURL('expedition'),
      eSanqua: buildType.apiURL('esanqua'),
      hrsApi: buildType.apiURL('hrs'),
      proc: buildType.apiURL('proc'),
      build: process.env.VUE_APP_BUILD_TYPE,
      loading: false,
      detail: null,
      form: {
        order_type: 2,
        odoo_order_no: '',
        order_date: '',
        company: null,
        customer: null,
        loading_from_location: null,
        unloading_location: null,
        vehicle: null,
        driver: null,
        order_details: [],
        note: '',
        product: null,
        qty: 0,
        unit: null
      },
      itemTableHeaders: [
        { text: 'Nama', value: 'product_name', align: 'left' },
        { text: 'Qty', value: 'qty', align: 'left' },
        { text: 'Satuan', value: 'uom_name', align: 'left' },
        { text: '', value: 'actions', align: 'left' }
      ],
      dropdown: {
        company: [],
        customer: [],
        prodcut: [],
        unit: [],

        loadingLocation: [],
        loadingLocationSearch: null,
        loadingLocationSelect: null,

        unloadingLocation: [],
        unloadingLocationSearch: null,
        unloadingLocationSelect: null,

        driver: [],
        driverSearch: null,
        driverSelect: null,

        vehicle: [],
        vehicleSearch: null,
        vehicleSelect: null
      },
      rules: {
        order_no: [],
        order_date: [],
        company: [],
        customer: [],
        vehicle: [],
        driver: [],
        loading: [],
        unloading: []
      }
    }
  },
  mounted() {
    this.initDropdown()
  },
  watch: {
    'dropdown.loadingLocationSearch'(val) {
      if (val !== '') {
        val &&
          val !== this.dropdown.loadingLocationSelect &&
          this.dropdownLoadingLocation(val)
      } else {
        this.dropdown.loadingLocationSelect = null
        this.dropdown.loadingLocation = []
      }
    },
    'dropdown.unloadingLocationSearch'(val) {
      if (val !== '') {
        val &&
          val !== this.dropdown.unloadingLocationSelect &&
          this.dropdownUnloadingLocation(val)
      } else {
        this.dropdown.unloadingLocationSelect = null
        this.dropdown.unloadingLocation = []
      }
    },
    'dropdown.driverSearch'(val) {
      if (val !== '') {
        val && val !== this.dropdown.driverSelect && this.dropdownDriver(val)
      } else {
        this.dropdown.driverSelect = null
        this.dropdown.driver = []
      }
    },
    'dropdown.vehicleSearch'(val) {
      if (val !== '') {
        val && val !== this.dropdown.vehicleSelect && this.dropdownVehicle(val)
      } else {
        this.dropdown.vehicleSelect = null
        this.dropdown.vehicle = []
      }
    }
  },
  methods: {
    itemDelete(item, index) {
      this.form.order_details.splice(index, 1)
    },
    addItem() {
      const items = {
        product_code: this.form.product.code,
        product_name: this.form.product.name,
        qty: this.form.qty,
        uom_name: this.form.unit.name
      }
      this.form.order_details.push(items)
      setTimeout(() => {
        this.form.product = null
        this.form.qty = 0
        this.form.unit = null
      }, 200)
    },
    back() {
      this.detail = null
      this.$router.push('/expedition/ordertransaction')
    },
    clearForm() {
      this.$refs.entryForm.reset()
      this.form = {
        order_type: 2,
        odoo_order_no: '',
        order_date: '',
        company: null,
        customer: null,
        loading_from_location: null,
        unloading_location: null,
        vehicle: null,
        driver: null,
        note: '',
        order_details: [],
        product: null,
        qty: 0,
        unit: null
      }
    },
    submit() {
      this.rules = {
        order_no: [v => !!v || 'Order No is required'],
        order_date: [v => !!v || 'Date is required'],
        company: [v => !!v || 'Company is required'],
        customer: [v => !!v || 'Customer is required'],
        vehicle: [v => !!v || 'Vehicle is required'],
        driver: [v => !!v || 'Driver is required'],
        loading: [v => !!v || 'Loading is required'],
        unloading: [v => !!v || 'Unloading is required']
      }
      const self = this
      setTimeout(function() {
        if (self.$refs.entryForm.validate()) {
          self.createNewForm()
        }
      })
    },
    createNewForm() {
      const form = {
        order_type: this.form.order_type,
        odoo_order_no: this.form.odoo_order_no,
        order_date: this.form.order_date,
        company_id: this.form.company.id,
        company_name: this.form.company.name,
        customer_id: Number(this.form.customer.id),
        sub_customer_id: null,
        sub_customer_odoo_id: null,
        customer_name: this.form.customer.name,
        customer_code: this.form.customer.code,
        sub_customer_code: null,
        customer_address: this.form.customer.address,
        loading_from_company_id: null,
        loading_from_company_name: null,
        loading_from_location_id: this.form.loading_from_location.id,
        unloading_location_id: this.form.unloading_location.id,
        vehicle_id: null,
        license_plate: this.form.vehicle.license_plate,
        driver_id: this.form.driver.nik,
        driver_name: this.form.driver.name,
        note: this.form.note,
        source_data: 'external',
        order_details: this.form.order_details
      }
      console.log(form)
      this.save(form)
    },
    async save(form) {
      const self = this
      this.loading = true
      await axios
        .post(`${this.expedition}transaction/add`, form)
        .then(res => {
          console.log(res.data)
          this.loading = false
          if (res.data.status_code !== '-99') {
            this.clearForm()
            setTimeout(() => {
              self.$router.push(
                `/expedition/ordertransaction/detail/${res.data.data.order_id}`
              )
            }, this.showMsgDialog('success', res.data.status_msg, false))
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              return this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              return this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          return (this.loading = false)
        })
    },

    async initDropdown() {
      this.dropdownCompany()
      this.dropdownCustomer()
      this.dropdownProduct()
      this.dropdownUnit()
    },

    async dropdownLoadingLocation(v) {
      await axios
        .get(`${this.expedition}location/dropdown?keyword=${v}`)
        .then(res => {
          this.loading = false
          if (res.data.status_code === '00') {
            return (this.dropdown.loadingLocation = res.data.data)
          }
          return (this.dropdown.loadingLocation = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.loadingLocation = [])
        })
    },
    async dropdownUnloadingLocation(v) {
      await axios
        .get(`${this.expedition}location/dropdown?keyword=${v}`)
        .then(res => {
          this.loading = false
          if (res.data.status_code === '00') {
            return (this.dropdown.unloadingLocation = res.data.data)
          }
          return (this.dropdown.unloadingLocation = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.unloadingLocation = [])
        })
    },
    async dropdownDriver(v) {
      axios
        .get(`${this.hrsApi}employee/list?keyword=${v}&offset=0&limit=10`)
        .then(res => {
          if (res.data.status_code === '00') {
            const arr = []
            for (let i = 0; i < res.data.data.length; i++) {
              arr.push({
                id: res.data.data[i].id,
                name: res.data.data[i].name,
                nik: res.data.data[i].nik
              })
            }
            return (this.dropdown.driver = res.data.data)
          }
          return (this.dropdown.driver = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.driver = [])
        })
    },
    async dropdownVehicle(v) {
      await axios
        .get(`${this.expedition}vehicle/dropdown?keyword=${v}`)
        .then(res => {
          this.loading = false
          if (res.data.status_code === '00') {
            return (this.dropdown.vehicle = res.data.data)
          }
          return (this.dropdown.vehicle = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.vehicle = [])
        })
    },
    async dropdownCompany() {
      this.dropdown.company = []
      await axios
        .get(`${this.eSanqua}master/universal/plant/dropdown`)
        .then(res => {
          if (res.data.status_code === '00') {
            const arr = res.data.data
            return (this.dropdown.company = arr)
          }
          return (this.dropdown.company = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.company = [])
        })
    },
    async dropdownCustomer(v) {
      await axios
        .get(`${this.expedition}customer/dropdown`)
        .then(res => {
          this.loading = false
          if (res.data.status_code === '00') {
            return (this.dropdown.customer = res.data.data)
          }
          return (this.dropdown.customer = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.customer = [])
        })
    },
    async dropdownProduct(v) {
      await axios
        .get(`${this.expedition}product/dropdown`)
        .then(res => {
          console.log(res)
          this.loading = false
          if (res.data.status_code === '00') {
            return (this.dropdown.product = res.data.data)
          }
          return (this.dropdown.product = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.product = [])
        })
    },
    async dropdownUnit(v) {
      await axios
        .get(`${this.proc}master/unit/drop_down?keyword=`)
        .then(res => {
          console.log(res)
          this.loading = false
          if (res.data.status_code === '00') {
            return (this.dropdown.unit = res.data.data)
          }
          return (this.dropdown.unit = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.unit = [])
        })
    },

    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    priceFormat(val) {
      if (val !== undefined) {
        if (val !== null) {
          return val.toString().replace(/\B(?<!\\d*)(?=(\d{3})+(?!\d))/g, ',')
        }
      }
      return ''
    }
  }
}
</script>
